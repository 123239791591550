import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLifeRing,
  faBullhorn,
  faMoneyBillAlt,
  faPiggyBank,
} from '@fortawesome/pro-light-svg-icons';
import securinglifeLogo from '../images/securing-life-logo.png';
import dcomLogo from '../images/dcomLogo.png';
import heroImg from '../images/hero-image.png';
import BorderedBox from '../components/BorderedBox/BorderedBox';

const HeroContent = () => (
  <BorderedBox>
    <h1>Times are tough.</h1>
    <h2>We&#39;re making them easier.</h2>
  </BorderedBox>
);

const CopyWithPhone = ({ phoneNumber, phoneCopy }) => (
  <p>
    Call
    <a href={`tel:${phoneNumber}`}>{` ${phoneCopy} `}</a>
    to connect with someone who can answer your questions now or fill out the form below.
  </p>
);

CopyWithPhone.propTypes = {
  phoneNumber: PropTypes.string.isRequired,
  phoneCopy: PropTypes.string.isRequired,
};

const securinglifeData = {
  logos: [
    {
      id: 'securinglifeLogo',
      logoImg: securinglifeLogo,
      logoLink: 'https://securing-life.com/?utm_source=debtcomhelpline&utm_medium=referral',
    },
    { id: 'dcomLogo', logoImg: dcomLogo, logoLink: 'https://debt.com/?b=3075b&s2=helplinelogo' },
  ],
  headerPhone: {
    headline: 'Ask Your Question Now!',
    number: '8448331658',
    text: '(844) 833-1658',
  },
  credibilityLogos: {
    unitedWayCopy: () => (
      <p className="modal-copy">
        &#34;We are proud to partner with Debt.com&#34; Kathleen Cannon, President & CEO of United
        Way of Broward County. Debt.com supports the mission of the United Way and is a proud member
        of the United Way Chairman&#39;s Circle Organization.
      </p>
    ),
    bbbCopy: () => (
      <p className="modal-copy">
        Debt.com has been a BBB Accredited Business since 2015 and has a current A+ rating. Call us
        today and see what we can do for you.
        <a
          href="https://www.bbb.org/us/fl/plantation/profile/credit-services/debtcom-llc-0633-90080459"
          rel="noreferrer"
          target="_blank"
        >
          View the debt.com BBB review status.
        </a>
      </p>
    ),
    buttonCopy: 'Get Answers',
  },
  heroSection: {
    heroImg,
    heroContent: <HeroContent />,
  },
  infoSection: {
    copyOnTop:
      "At Securing Life, we understand that you may be facing immense debt and credit challenges as a result of the coronavirus and economic shutdown. That's why we've partnered up with Debt.com to provide this free service to answer any questions you have about your debt, budget, mortgage, credit and taxes.",
    copyOnBottom: (number, text) => <CopyWithPhone phoneCopy={text} phoneNumber={number} />,
  },
  helpSection: {
    topCopy:
      'Debt.com specializes in helping people connect with the right information, services, and tools to lead financially healthy lives. During this crisis, that means keeping people updated on:',
    servicesList: [
      {
        icon: <FontAwesomeIcon icon={faLifeRing} size="3x" />,
        copy: 'Federal and state government relief effort',
      },
      {
        icon: <FontAwesomeIcon icon={faBullhorn} size="3x" />,
        copy: 'Support announcements from banks and credit card companies',
      },
      {
        icon: <FontAwesomeIcon icon={faMoneyBillAlt} size="3x" />,
        copy: 'Advice for budgeting and addressing income loss',
      },
      {
        icon: <FontAwesomeIcon icon={faPiggyBank} size="3x" />,
        copy: 'Tips on saving, financial planning, and managing debt',
      },
    ],
    cta: {
      copy: "Visit Debt.com's COVID-19 Resource Center",
      link: 'https://www.debt.com/covid-19/?b=3075b&s2=covidresources',
    },
  },
  form: {
    headline: 'What do you have questions about?',
    topic: {
      label: 'Topic',
      options: [
        {
          id: 'credit_card_debt',
          value: 'Credit Card Debt',
          text: 'Credit Card Debt',
          isDefault: true,
        },
        {
          id: 'budget_questions',
          value: 'Financial Planning',
          text: 'Budget questions',
          isDefault: false,
        },
        {
          id: 'mortgage',
          value: 'Other',
          text: 'Mortgage',
          isDefault: false,
        },
        {
          id: 'taxes',
          value: 'Tax Debt',
          text: 'Taxes',
          isDefault: false,
        },
        {
          id: 'student_loans',
          value: 'Student Loan Debt',
          text: 'Student loans',
          isDefault: false,
        },
        {
          id: 'credit_profile',
          value: 'Credit Repair',
          text: 'Credit reports and scores',
          isDefault: false,
        },
      ],
      error: 'Please select a topic.',
    },
    questions: {
      label: 'What do you have questions about?',
      errorRequired: 'Please enter your question.',
      otherError: '',
    },
    nextBtn: 'Next',
    name: {
      label: 'First Name',
      errorRequired: 'Please enter your first name.',
      otherError: 'Please enter a valid first name.',
    },
    lastname: {
      label: 'Last Name',
      errorRequired: 'Please enter your last name.',
      otherError: 'Please enter a valid last name.',
    },
    email: {
      label: 'Email Address',
      errorRequired: 'Please enter your email address.',
      otherError: 'Please enter a valid email address.',
    },
    zipField: {
      label: 'Zip Code',
      errorRequired: 'Please enter your zip code.',
      otherError: 'Please enter a valid zip code.',
    },
    phoneField: {
      label: 'Phone Number',
      errorRequired: 'Please enter your phone number.',
      otherError: 'Please enter a valid phone number.',
    },
    backBtn: 'Back',
    submitBtn: 'Submit',
    successMsg: (realPhone) => (
      <div className="final-message">
        <h3>Thanks for submitting your questions.</h3>
        <p>
          We’re already working to get you the answers you need as quickly as possible. We’ve sent
          your questions to an expert who will be in touch soon to help you find the right
          information and resources to move forward. If you need immediate help, please call
          <a href={`tel:+${realPhone.number}`}>
            <strong>{` ${realPhone.text} `}</strong>
          </a>
          to speak with someone directly so you can get support now.
        </p>
      </div>
    ),
    errorMsg: (realPhone) => (
      <>
        <h3>We’re sorry! Something went wrong.</h3>
        <p>
          We were not able to process your questions, but we want to ensure you can get the help you
          need without delay. You can try reloading this page to submit your questions again or you
          can call us at
          <a href={`tel:+${realPhone.number}`}>
            <strong>{` ${realPhone.text} `}</strong>
          </a>
          to speak with an expert directly over the phone.
        </p>
      </>
    ),
  },
  disclaimer: {
    copy: 'Terms & Conditions.',
    link: 'https://www.debt.com/terms-conditions/?b=3075b&s2=tc',
  },
  footer: [
    {
      copy: 'Privacy Policy',
      link: 'https://www.debt.com/privacy-policy/?b=3075b&s2=pp',
    },
    {
      copy: 'Terms & Conditions',
      link: 'https://www.debt.com/terms-conditions/?b=3075b&s2=tc',
    },
    {
      copy: 'Advertising Disclosures',
      link: 'https://www.debt.com/advertising-disclosures/?b=3075b&s2=advdis',
    },
    {
      copy: 'California – Do Not Sell My Personal Information',
      link: 'https://www.debt.com/do-not-sell-my-personal-information/?b=3075b&s2=dnspi',
    },
  ],
};

export default securinglifeData;
